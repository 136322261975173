import { cva } from 'class-variance-authority'
import { type ReactNode } from 'react'
import { cn } from '~/utils/cn'

const variants = cva('border p-4', {
	variants: {
		color: {
			neutral: 'border-neutral-400 bg-neutral-100',
			pink: 'border-pink-400 bg-pink-200',
			blue: 'border-blue-400 bg-blue-200',
			green: 'border-green-400 bg-green-200',
			red: 'border-red-400 bg-red-200',
			success: 'bg-success border-none',
		},
		spacing: {
			none: 'space-y-0',
			small: 'space-y-2',
			medium: 'space-y-4',
			large: 'space-y-6',
		},
		rounded: {
			none: 'rounded-none',
			small: 'rounded-sm',
			medium: 'rounded-md',
			large: 'rounded-lg',
		},
		shadow: {
			none: 'shadow-none',
			small: 'shadow-sm',
			medium: 'shadow-md',
			large: 'shadow-lg',
		},
	},
	defaultVariants: {
		color: 'neutral',
		spacing: 'none',
		rounded: 'medium',
		shadow: 'medium',
	},
})

export function Box({
	children,
	color,
	spacing,
	rounded,
	shadow,
	className,
}: {
	children: ReactNode
	color?: 'neutral' | 'pink' | 'blue' | 'green' | 'red' | 'success'
	spacing?: 'none' | 'small' | 'medium' | 'large'
	rounded?: 'none' | 'small' | 'medium' | 'large'
	shadow?: 'none' | 'small' | 'medium' | 'large'
	className?: string
}) {
	return (
		<div
			className={cn(variants({ color, spacing, rounded, shadow }), className)}
		>
			{children}
		</div>
	)
}
